<template>
    <div class=" flex justify-content-left">
        <Breadcrumb :home="home" :model="breadcrumbData">
            <template #item="{ item, props }">
                <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                    <a :href="href" v-bind="props.action" @click="navigate">
                        <span :class="[item.icon, 'text-color']" />
                        <span class="text-primary font-semibold">{{ item.label }}</span>
                    </a>
                </router-link>
                <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                    <span class="text-color">{{ item.label }}</span>
                </a>
            </template>
        </Breadcrumb>
    </div>
</template>
<script setup>
    import { ref } from "vue";
    const route = useRoute();

    const breadcrumbData = useState('breadcrumbData', () => ref([{ label: route.meta.title}]));


    const home = ref({
        icon: 'pi pi-home',
        route: '/dashboard'
    });
</script>